import React, { useEffect, useRef, useState } from 'react';
import {Button, Form, Grid, Icon, Message, Modal, Popup} from 'semantic-ui-react';
import Search from '../../../components/Search';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { shippingRegisterDownloadSelector } from '../../../ducks/profile';
import {
    autogroupingOrdersSelector,
    canExportToExcelSelector,
    canImportFromExcelSelector,
    exportProgressSelector,
    exportToExcelRequest,
    importFromExcelRequest,
    importProgressSelector,
} from '../../../ducks/gridList';
import CustomIcon from '../../CustomIcon'
import { 
    shippingRegistersConfigRequest, 
    exportShippingRegistersRequest,
    shippingRegisterConfigSelector, 
    progressShippingRegistersConfigSelector,
    progressShippingRegistersExportSelector, 
    shippingRegistersConfigSelector,
} from '../../../ducks/gridActions';
import FieldsConfig from './representations';
import {
    getRepresentationsRequest,
    representationsSelector,
    setRepresentationRequest,
} from '../../../ducks/representations';
import { GRID_AUTO_GROUPING_LINK } from '../../../router/links';
import useReactRouter from 'use-react-router';
import {ORDERS_GRID, SHIPPINGS_GRID} from "../../../constants/grids";
import {ordersLoadingSelector} from "../../../ducks/loadingData";
import Date from "../../BaseComponents/Date";
import FormField from "../../BaseComponents";
import {calculationDeliveryRequest} from "../../../ducks/gridCard";
import {dateToString} from "../../../utils/dateTimeFormater";
import { toast } from 'react-toastify';
import { SELECT_TYPE, BOOLEAN_TYPE } from '../../../constants/columnTypes';

const Header = ({
    isCreateBtn,
    searchValue,
    searchOnChange,
    counter,
    clearFilter,
    disabledClearFilter,
    loadList,
    name,
    setSelected,
    representationName,
    filter,
    goToCard,
    width,
    pageLoading,
    selectedRows
}) => {
    const { t } = useTranslation();

    const dispatch = useDispatch();

    const fileUploader = useRef(null);

    const { history } = useReactRouter();

    const defaultModalValue = { open: false, from: dateToString(), to: dateToString() };
    let [openModal, setOpenModal] = useState(defaultModalValue);

    const defaultRegistersFormValue = { useVat: false };
    let [openShippingRegisterDownload, setOpenShippingRegisterDownload] = useState(false);
    let [shippingRegistersForm, setShippingRegistersForm] = useState(defaultRegistersFormValue);

    const isImportBtn = useSelector(state => canImportFromExcelSelector(state, name));
    const isExportBtn = useSelector(state => canExportToExcelSelector(state, name));
    const isShippingRegisterBtn = useSelector(state => shippingRegisterDownloadSelector(state));

    const shippingRegistersConfig = useSelector(state => shippingRegistersConfigSelector(state));

    const importLoader = useSelector(state => importProgressSelector(state));
    const exportLoader = useSelector(state => exportProgressSelector(state));
    const loadingOrders = useSelector(ordersLoadingSelector);
    const shippingRegistersConfigLoader = useSelector(state => progressShippingRegistersConfigSelector(state));
    const shippingRegistersExportLoader = useSelector(state => progressShippingRegistersExportSelector(state));

    const representations = useSelector(state => representationsSelector(state, name));

    const exportExcel = () => {
        dispatch(exportToExcelRequest({ name, filter: filter.filter }));
    };

    const importExcel = () => {
        fileUploader && fileUploader.current.click();
    };

    const onFilePicked = e => {
        const file = e.target.files[0];

        const data = new FormData();
        data.append('FileName', file.name);
        data.append('FileContent', new Blob([file], { type: file.type }));
        data.append('FileContentType', file.type);

        dispatch(
            importFromExcelRequest({
                name,
                form: data,
                callbackSuccess: () => loadList(false, true),
            }),
        );
    };

    const getRepresentations = callBackFunc => {
        dispatch(getRepresentationsRequest({ key: name, callBackFunc }));
    };

    const changeRepresentation = (key, repName, isEdit) => {
        dispatch(
            setRepresentationRequest({
                gridName: name,
                value: key,
                name: repName,
                callbackSuccess: () => {
                    setSelected(new Set());
                    pageLoading && pageLoading();
                },
            }),
        );
    };

    const handleGoToCard = () => {
        goToCard(false, null, name);
    };

    const handleCalculationDelivery = () => {
        dispatch(
            calculationDeliveryRequest({
                params: {
                    from: openModal.from,
                    to: openModal.to,
                },
                callbackSuccess: () => {
                    loadList(false, true);
                    setOpenModal(defaultModalValue);
                }
            }),
        );
    };

    const handleChangeCalculationDeliveryModal = value => {
        setOpenModal(prevState => ({
            ...prevState,
            ...value,
        }));
    };

    const handleOpenShippingRegisters = () => {
        dispatch(shippingRegistersConfigRequest({
            callbackSuccess: () => {
                setOpenShippingRegisterDownload(true);
            }
        }))
    }

    const handleChangeShippingRegisters = (e, {name, value}) => {
        const newForm = Object.assign(shippingRegistersForm);
        newForm[name] = value;
        setShippingRegistersForm(newForm);
    }

    const handleExportShippingRegisters = () => {
        if (shippingRegistersForm && shippingRegistersForm.registerId) {
            dispatch(exportShippingRegistersRequest({
                form: shippingRegistersForm,
                callbackSuccess: () => {
                    setOpenShippingRegisterDownload(false);
                    setShippingRegistersForm(defaultRegistersFormValue);
                }
            }))
        } else {
            toast.error(t("registerIdNull"));
        }
        
    }

    return (
        <Grid className="grid-header-panel">
            <Grid.Row>
                <Grid.Column width={5}>
                    <FieldsConfig
                        gridName={name}
                        width={width}
                        representationName={representationName}
                        getRepresentations={getRepresentations}
                        changeRepresentation={changeRepresentation}
                        representations={representations}
                    />
                </Grid.Column>
                <Grid.Column width={1} verticalAlign="middle">
                    <span className="records-counter">{t('totalCount', { count: counter })}</span>
                </Grid.Column>
                <Grid.Column width={3}>
                    {
                        name === ORDERS_GRID && loadingOrders
                        ? <Message info>
                            <Icon name="clock"/>
                                Идет загрузка данных...
                            </Message>
                        : null
                    }

                </Grid.Column>
                <Grid.Column width={7} className="grid-right-elements">
                    {isCreateBtn && (
                        <Popup
                            content={t('add_record')}
                            position="bottom right"
                            trigger={<Button icon="add" onClick={handleGoToCard} />}
                        />
                    )}
                    {name === SHIPPINGS_GRID && (
                        <Popup
                            content={t('Calculation of delivery to the forwarder is warehouse')}
                            position="bottom right"
                            trigger={<Button
                                icon="calculator"
                                onClick={() => handleChangeCalculationDeliveryModal({ open: true })}
                            />}
                        />

                    )}
                    {name === SHIPPINGS_GRID && isShippingRegisterBtn && (
                        <Popup
                            content={t('shippingRegisterDownload')}
                            position="bottom right"
                            trigger={<Button
                                icon="table"
                                loading={shippingRegistersConfigLoader}
                                onClick={handleOpenShippingRegisters}
                            />}
                        />

                    )}
                    {isImportBtn && (
                        <Popup
                            content={t('importFromExcel')}
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="upload"
                                    loading={importLoader}
                                    onClick={importExcel}
                                />
                            }
                        />
                    )}
                    {isExportBtn && (
                        <Popup
                            content={
                                t('exportToExcel') // todo
                            }
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="download"
                                    loading={exportLoader}
                                    onClick={exportExcel}
                                />
                            }
                        />
                    )}
                    <Popup
                        content={t('reset_filters')}
                        position="bottom right"
                        trigger={
                            <Button
                                icon
                                className={`clear-filter-btn`}
                                onClick={clearFilter}
                                disabled={disabledClearFilter}
                            >
                                <CustomIcon name="clear-filter" />
                            </Button>
                        }
                    />
                    <Search
                        searchValue={searchValue}
                        className="search-input"
                        value={filter.filter.filter.search}
                        onChange={searchOnChange}
                    />
                </Grid.Column>
            </Grid.Row>
            <input
                type="file"
                ref={fileUploader}
                style={{ display: 'none' }}
                onInput={onFilePicked}
            />
            <Modal open={openModal.open}>
                <Modal.Header>{t('Select dates')}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Grid>
                            <Grid.Row columns={2}>
                                <Grid.Column>
                                    <Form.Field>
                                        <Date
                                            value={openModal.from}
                                            name="from"
                                            onChange={(e, { value }) =>
                                                handleChangeCalculationDeliveryModal({
                                                    from: value,
                                                })
                                            }
                                        />
                                    </Form.Field>
                                </Grid.Column>
                                <Grid.Column>
                                    <Form.Field>
                                        <Date
                                            value={openModal.to}
                                            name="to"
                                            onChange={(e, { value }) =>
                                                handleChangeCalculationDeliveryModal({ to: value })
                                            }
                                        />
                                    </Form.Field>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenModal(defaultModalValue)}>
                        {t('CancelButton')}
                    </Button>
                    <Button primary onClick={handleCalculationDelivery}>
                        {t('Calculate')}
                    </Button>
                </Modal.Actions>
            </Modal>
            <Modal open={openShippingRegisterDownload}>
                <Modal.Header>{t('shippingRegisterDownload')}</Modal.Header>
                <Modal.Content>
                    <Form>
                        <Grid>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <FormField
                                        name="registerId"
                                        columnsConfig={shippingRegistersConfig.columns}
                                        type={SELECT_TYPE}
                                        source="shippingRegisters"
                                        value={shippingRegistersForm["registerId"]}
                                        onChange={handleChangeShippingRegisters}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                            <Grid.Row columns={1}>
                                <Grid.Column>
                                    <FormField
                                        name="useVat"
                                        columnsConfig={shippingRegistersConfig.columns}
                                        type={BOOLEAN_TYPE}
                                        value={shippingRegistersForm["useVat"]}
                                        onChange={handleChangeShippingRegisters}
                                    />
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>
                    </Form>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={() => setOpenShippingRegisterDownload(false)}>
                        {t('CancelButton')}
                    </Button>
                    <Button primary loading={shippingRegistersExportLoader} onClick={handleExportShippingRegisters}>
                        {t('shippingRegisterDownloadBtn')}
                    </Button>
                </Modal.Actions>
            </Modal>
        </Grid>
    );
};

export default Header;
