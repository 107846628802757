import React, {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import CellValue from '../../ColumnsValue';
import {Form, Icon, Loader, Table} from 'semantic-ui-react';
import {toast} from 'react-toastify';
import FormField from '../../BaseComponents';
import {LINK_TYPE} from '../../../constants/columnTypes';
import _ from 'lodash';

const ModalComponent = ({ element, props, children }) => {
    if (!element) {
        return <>{children}</>;
    }
    return React.cloneElement(element, props, children);
};

const BodyCell = ({
    value,
    valueText,
    valueTooltip,
    column,
    loadList,
    indexRow,
    indexColumn,
    modalCard,
    gridName,
    t,
    checkForEditing,
    invokeMassUpdate,
    status,
    rowId,
    checkForClickedPosition,
    rowNumber,
    cardLink,
    goToCard,
    row,
}) => {
    const contextRef = useRef(null);
    const ref = useRef(null);
    const formRef = useRef(null);
    const editableFieldRef = useRef(null);

    let [open, setOpen] = useState(false);
    let [valueForm, setValue] = useState(null);
    let [clickedInUpperHalf, setClickedInUpperHalf] = useState(false);
    let [progress, setProgress] = useState(false);

    const newColumn = useMemo(() => {
        let newColumn = {
            ...column,
        };
        if (column.dependencies && column.dependencies.length) {
            let sourceParams = {};

            column.dependencies.forEach(item => {
                sourceParams = {
                    ...sourceParams,
                    [item]:
                        row[item] && typeof row[item] === 'object' ? row[item].value : row[item],
                };
            });

            newColumn = {
                ...newColumn,
                sourceParams,
            };
        }

        return newColumn;
    }, [column, row]);



    

    useEffect(() => {
        open && document.addEventListener('mousedown', handleClickOutside, true);
        !open && document.removeEventListener('mousedown', handleClickOutside, true);
        
        
        return () => {
            document.removeEventListener('mousedown', handleClickOutside, true);
        };
    }, [open, valueForm]);

    useEffect(() => {
        if (open) {
            const { x, width, right } = editableFieldRef.current.getBoundingClientRect();
            const blockWidth = window.innerWidth - 30;


            if (x < 50 + width) {
                document
                    .getElementById('scroll-grid-container')
                    .scrollTo(
                        document.getElementById('scroll-grid-container').scrollLeft - width,
                        document.getElementById('scroll-grid-container').scrollTop,
                    );
            }

            if (x + width >= blockWidth) {
                document
                    .getElementById('scroll-grid-container')
                    .scrollTo(
                        document.getElementById('scroll-grid-container').scrollLeft + width,
                        document.getElementById('scroll-grid-container').scrollTop,
                    );
            }
        }
    }, [open]);

    const handleClickOutside = event => {
        if (ref.current && !ref.current.contains(event.target)) {
            handleSave();
        }
    };

    const copyToClipboard = () => {
        const text = contextRef.current && contextRef.current.textContent;
        navigator.clipboard &&
            navigator.clipboard.writeText(text).then(
                () => {
                    toast.info(t('copied_to_clipboard_success'));
                },
                error => {
                    toast.error(t('copied_to_clipboard_error', { error }));
                },
            );
    };

    const handleClick = (rowId, fieldName, state) => {
        setProgress(true);
        checkForEditing({
            rowId,
            fieldName,
            state,
            forEntity: gridName,
            t,
            callbackSuccess: () => {
                handleOpen();
            },
            callbackFunc: () => {
                setProgress(false);
            },
        });
    };

    const handleOpen = () => {
        setOpen(true);
        setValue(valueText ? { value, name: valueText } : value);
    };

    const handleClose = () => {
        setOpen(false);
        setValue(null);
    };

    const handleSave = () => {
        invokeMassUpdate({
            ids: [rowId],
            name: gridName,
            field: column.name,
            value:
                typeof valueForm === 'number' || typeof valueForm === 'boolean'
                    ? valueForm.toString()
                    : valueForm, //todo проблема на бэке при передаче числового значения
            callbackSuccess: () => {
                setProgress(true);
                loadList(false, true);
                setProgress(false);
                handleClose();
            },
        });
    };

    const handleChange = useCallback(
        (e, { value }) => {
            setValue(value);
        },
        [value],
    );

    const handleCellClick = e => {

        column.type !== LINK_TYPE ? handleClick(rowId, column.name, status) : e.stopPropagation();
    };

    const getModalCard = useCallback(() => {
        return (
            <ModalComponent
                element={modalCard()}
                props={{
                    id: rowId,
                    status,
                    loadList,
                    title: `edit_${gridName}`,
                }}
                key={`modal_${rowId}`}
            />
        );
    }, []);

    return (
        <>
            <Table.Cell className="value-cell">
                <div className="cell-grid" ref={ref}>
                    {open ? (
                        <div className="cell-grid-form" ref={editableFieldRef}>
                            <Form ref={formRef} onSubmit={handleSave}>
                                <FormField
                                    {...newColumn}
                                    isGrid
                                    noLabel
                                    autoFocus
                                    autoComplete
                                    formRef={formRef}
                                    value={valueForm}
                                    checkForClickedPosition={checkForClickedPosition}
                                    onChange={handleChange}
                                />
                            </Form>
                        </div>
                    ) : (
                        <div
                            className={`cell-grid-value ${value ? '' : 'cell-grid-value_empty'}`}
                            ref={contextRef}
                            onClick={handleCellClick}
                        >
                            <CellValue
                                {...column}
                                indexRow={indexRow}
                                indexColumn={indexColumn}
                                value={value}
                                valueText={valueText}
                                valueTooltip={valueTooltip}
                                width={column.width}
                                gridName={gridName}
                                rowId={rowId}
                                t={t}
                                goToCard={goToCard}
                            />
                        </div>
                    )}
                    <div>
                        {progress ? (
                            <Loader active={true} size="mini" />
                        ) : (
                            <>
                                {value !== null && !open ? (
                                    <div className="cell-grid-copy-btn">
                                        <Icon
                                            name="clone outline"
                                            size="small"
                                            onClick={copyToClipboard}
                                        />
                                    </div>
                                ) : null}
                            </>
                        )}
                    </div>
                </div>
            </Table.Cell>
            {/* <Modal open={open} size="tiny" closeOnDimmerClick={false}>
                <Modal.Header>
                    {t(`edit_${gridName}`, {
                        number: rowNumber,
                        status: t(status),
                    })}
                </Modal.Header>
                <Modal.Content>
                    <Modal.Description>
                        <Form onSubmit={handleSave}>
                            <FormField {...column} autoFocus value={valueForm} onChange={handleChange} />
                        </Form>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button onClick={handleClose}>{t('cancelConfirm')}</Button>
                    <Button
                        color="primary"
                        disabled={_.isEqual(valueForm, value)}
                        onClick={handleSave}
                    >
                        {t('SaveButton')}
                    </Button>
                </Modal.Actions>
            </Modal>*/}
        </>
    );
};

export default React.memo(
    BodyCell , (prevProps, nextProps) => {
        if (_.isEqual(prevProps.row, nextProps.row)) {
            return true;
        }
    return false;
},
);
